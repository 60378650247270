<template>
  <div class="container" id="user_profile">
    <b-row>
      <b-col lg="8">
        <b-row>
          <b-col sm="12">
            <iq-card class="iq-card" >
              <div class="iq-card-body profile-page p-0">
                <div class="profile-header">
                  <div class="cover-container" style="overflow:hidden;">
                    <img v-if="vmCoverPic" :src="vmCoverPic" class="rounded img-fluid" style="object-fit: cover; height:250px; width: 100%;">
                    <img v-else :src="vmBannerImage" class="rounded img-fluid" style="object-fit: cover; height:250px; width: 100%;">
                  </div>
                  <div class="user-detail text-center mb-3">
                    <div class="profile-img" >
                      <img v-if="vmProfilePic" :src="vmProfilePic" alt="profile-img" class="img-fluid rounded-circle" style="object-fit: cover; width:100px; height:100px;" />
                      <b-avatar v-else variant="primary" class="avatar-100 rounded" :text="getFirstLetterOfAString(userObj.user_name)" style="border-radius: 100%!important;"></b-avatar>
                    </div>
                    <div class="profile-detail">
                      <h3 class="details_flag">
                        {{userObj.user_name}}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </iq-card>
          <b-col class="col-12">
            <div class="tab-content">
              <tab-content-item :active="aboutActive" id="profile-activity" aria-labelled-by="pills-activity-tab">
                <div class="row">
                  <!-- Right Hand Side of My Profile -->
                  <div class="col-12 col-sm-12 col-md-12 p-0">
                    <!-- About Myself View Only Description -->
                    <About :propUserObj="userObj" :propShowReadMore="false" :propShowOnlyContactDetail='false' :key="UPDATED"/><!-- About Myself View Only Description -->

                    <!-- Education View -->
                    <div class="col-sm-12 col-lg-12 p-0">
                      <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                          <div class="iq-header-title w-100">
                            <h4 class="card-title">Education</h4>
                          </div>
                        </div>
                        <hr class="m-0"/>
                        <div class="iq-card-body">
                          <p class="mb-4" v-if="user_profile && user_profile.education && user_profile.education.length">
                            <Education :propUserView=false :propEducationList="user_profile.education" :propExcObjList="extraCurricularListObj"></Education>
                          </p>
                        </div>
                      </div>
                    </div><!-- Education View -->
                  </div><!-- Right Hand Side of My Profile -->
                </div>
              </tab-content-item>
            </div>
          </b-col>
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="4">
        <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog-post">
          <div class="iq-card-header d-flex justify-content-between">
            <div class="header-title">
              <h4 class="iq-card-title">Upcoming events</h4>
            </div>
          </div>
          <div class="iq-card-body right_side_bar">
            <ul class="row list-inline p-0 mb-0 ">
              <li class=" mb-2 col-12 col-xs-6 col-sm-6 col-md-6 col-lg-12" v-for="(newEvent, index) of vmEventlist" :key="index">
                <div class="row align-items-top pb-1 border-bottom pointer" @click="openUrlInNewTab('/public/GideEvent/'+newEvent.event_id)">
                  <div class="col-sm-12 col-md-5 col-lg-5">
                    <div class="image-block" v-if="newEvent.event_img">
                      <img :src="newEvent.event_img" class="img-fluid rounded w-100" style="height: 80px;" alt="blog-img">
                    </div>
                    <div v-else style="background-color: #EEEEEE; width: 100%; height: 80px; border-radius: 5px; display: flex; text-align: center;">
                      <span style="margin: auto; font-size: 36px;">{{ newEvent.event_name.slice(0, 1) }}</span>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-7 col-lg-7">
                    <div class="blog-description pl-2">
                      <h6 class="mb-0">{{ newEvent.event_name }}</h6>
                      <div class="date">
                        <a style="cursor: pointer;" tabindex="-1">
                          {{ newEvent.event_start_ts | dateFormatDDMMYYYYHHMMA }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { socialvue } from "../../../../config/pluginInit"
import About from "../../Profile/User/ProfileFriends/About.vue"
import { User } from "../../../../FackApi/api/user.js"
import { Images } from "../../../../FackApi/api/image"
import { Follows } from "../../../../FackApi/api/follow"
import ApiResponse from "../../../../Utils/apiResponse.js"
import Education from "../../../../components/UserProfile/Education.vue"
import { events } from "../../../../FackApi/api/events.js"

export default {
  name: "Profile",
  components: {
    About,
    Education
  },
  data () {
    return {
      userObj: {},
      vmEventlist: [],
      socialInfo: [
        {
          name: "Post",
          value: 10
        }
      ],
      userFollowObj: {},
      vmFollowData: Object.assign({}, this.initFollowData()),
      vmProfilePic: "",
      vmCoverPic: "",
      followStatusOfCurrUser: 0,
      followerApiFire: false,
      feedActive: false,
      aboutActive: true,
      vmBannerImage: "/img/background.jpg",
      user_profile: null,
      extraCurricularListObj: {},
      VUE_APP_ENV: process.env.VUE_APP_ENV,
      UPDATED: false,
      pp: "1"
    }
  },
  computed: {
  },
  mounted () {
    this.cvUserId = this.$route.params.user_id
    this.loadRequiredData()
  },
  methods: {
    /**
     * initFollowData
     */
    initFollowData () {
      return {
        module_id: "",
        module_name: "",
        follow_status: 0
      }
    },
    /**
    * openUrlInNewTab
    */
    openUrlInNewTab (url) {
      window.open(url, "_blank")
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * loadRequiredData
     */
    loadRequiredData () {
      socialvue.index()
      this.userViewPublic()
      this.userProfileView()
      this.imageGetProfileImages()
      this.getEventList()
    },
    /**
     * UserView
     */
    async userViewPublic () {
      try {
        let userViewResp = await User.userViewPublic(this, this.cvUserId, this.cvUserId, this.pp)

        if (userViewResp && userViewResp.resp_status) {
          this.userObj = userViewResp.resp_data.data
          this.userFollowObj = userViewResp.user_follow

          this.UPDATED = true

          if (userViewResp.post_count) {
            this.socialInfo[0]["value"] = userViewResp.post_count
          }

          if (userViewResp.user_follow && userViewResp.user_follow.follow_count) {
            this.socialInfo[1].value = userViewResp.user_follow.follow_count
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at userView() and Exception:", err.message)
      }
    },
    /**
     * userProfileView
     */
    async userProfileView () {
      let userProfileViewResp = await User.userPublicViewProfile(this, this.cvUserId)
      if (userProfileViewResp.resp_status) {
        this.o2o_participant_id = userProfileViewResp.resp_data.data.user_id
        userProfileViewResp.resp_data.user_id = userProfileViewResp.resp_data.data.user_id
        if (typeof userProfileViewResp.resp_data.data.user_profile === "string") {
          userProfileViewResp.resp_data.data.user_profile = JSON.parse(userProfileViewResp.resp_data.data.user_profile)
        }
        this.extraCurricularListObj = userProfileViewResp.resp_data.data.extraCurricularListObj
        this.user_profile = userProfileViewResp.resp_data.data.user_profile
      }
    },
    /**
     * imageGetProfileImages()
     */
    async imageGetProfileImages () {
      try {
        let imageProfileResp = await Images.imageListProfileImages(this, this.cvUserId, this.cvUserId)
        if (imageProfileResp && !imageProfileResp.resp_status) {
          return false
        }
        else {
          this.vmProfilePic = imageProfileResp.resp_data.profile_pic ? imageProfileResp.resp_data.profile_pic.image_thumbnail : ""
          this.vmCoverPic = imageProfileResp.resp_data.cover_pic ? imageProfileResp.resp_data.cover_pic.image : ""
        }
      }
      catch (err) {
        console.error("Exception occurred at imageGetProfileImages() and Exception:", err.message)
      }
    },
    /**
    * getEventList
    */
    async getEventList () {
      try {
        const filter = {
          limit: 10,
          upcoming_events: true
        }

        const eventResp = await events.eventListPublic(this, filter)
        if (eventResp.resp_status) {
          this.vmEventlist = eventResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Exception occurred at eventList() and Exception:", err.message)
      }
    },
    /**
     *  followUser()
     */
    async followUser (followStatus) {
      try {
        let userId = this.$route.params.user_id
        this.vmFollowData.module_id = userId
        this.vmFollowData.module_name = "USER"
        this.vmFollowData.follow_status = followStatus ? 0 : 1

        let followResp = await Follows.followAdd(this, this.vmFollowData)
        await ApiResponse.responseMessageDisplay(this, followResp)

        if (followResp && !followResp.resp_status) {
          return
        }
        else {
          this.userFollowObj.followed_by_curr_user = this.vmFollowData.follow_status
          this.followStatusOfCurrUser = this.vmFollowData.follow_status

          if (followStatus) {
            this.userFollowObj.follow_count--
          }
          else {
            this.userFollowObj.follow_count++
          }
          this.socialInfo[1].value = this.userFollowObj.follow_count
          this.vmFollowData = Object.assign({}, this.initFollowData())
        }
      }
      catch (err) {
        console.error("Exception occured at followUser() and Exception", err.message)
      }
    }
  },
  watch: {
    /**
     * cvUserId
     */
    async cvUserId () {
      await this.cvUserId
      this.loadRequiredData()
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  max-width: 1140px !important;
}
.pointer{
  cursor: pointer;
}
.browseMoreEvent {
  float: right;
  font-size: 12px;
  color: var(--iq-primary);
  cursor: pointer;
}
.educationAddIcon {
  float: right;
  color: var(--iq-primary);
  cursor: pointer;
}
@media (max-width: 760px){
  .showFlexListDiv .showFlexList .profile-feed-items {
  width: 100%;
  }
}
.profileMoreBtn{
  height: 25px;
}
.details_flag{
  margin: auto !important;
  position: relative;
  padding: 0px 40px;
  width: auto;;
  white-space: normal;
  .more_icon{
    position: absolute !important;
    right: -30px !important;
    top: -5px !important;
  }
}
.text_user_details{
  i{
    color: var(--iq-primary);
  }
}
#user_profile .profile-header .user-detail {
    position: relative;
    bottom: 30px;
    margin-top: -40px;
    height: auto;
}
.cover-container {
    height: auto;
}
@media (max-width: 767px){
  .user-detail{
    .profile-img{
      .header-nav{
        display: none !important;
      }
    }
  }
}
.menu_icon{
  #dropdownMenuButton40__BV_toggle_{
    a{
      display: block;
      width: 20px;
    }
  }
}
</style>
